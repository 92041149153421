<template>
  <div id="locationCertificationsPage">
    <LocationCertificationsHeroContainer
      v-if="locationCertificationsHero"
      :location-certifications-hero="locationCertificationsHero"
      @loaded="toggleShowPageTrue"
    />
  </div>
</template>

<script>
import api from '@/api'
import LocationCertificationsHeroContainer from '@/components/Hero/LocationCertificationsHeroContainer'
import viewMixin from '@/mixins/viewMixin'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'locationCertifications',
  components: { LocationCertificationsHeroContainer },
  mixins: [viewMixin],
  data() {
    return {
      locationCertificationsHero: null
    }
  },
  mounted() {
    this.loadLocationCertificationsPage()
  },
  methods: {
    loadLocationCertificationsPage() {
      const slug = this.$route.params.locationSlug

      this.setLocationCertificationsData(
        apiHelper.readStoredResponse(`locationCertifications.${slug}`)
      )

      api.certifications.loadLocationCertificationsPage(slug).then(response => {
        this.setLocationCertificationsData(response)
      })
    },
    setLocationCertificationsData(response) {
      if (!response || !response.entry) return

      this.locationCertificationsHero =
        response.entry.locationCertificationsHero[0]
    }
  }
}
</script>
