<template>
  <div
    class="location-certifications-hero-container"
    :style="
      `background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(82, 94, 110, 0.9)), url(${backgroundImageUrl});`
    "
  >
    <div class="container">
      <div class="certifications-body">
        <h1>{{ locationCertificationsHero.titleText }}</h1>
        <h2>{{ locationCertificationsHero.subtitle }}</h2>

        <div
          v-if="
            locationCertificationsHero.addressLink ||
              locationCertificationsHero.address
          "
          class="address"
        >
          <a
            v-if="locationCertificationsHero.addressLink.length"
            :href="locationCertificationsHero.addressLink"
            target="_blank"
          >
            <font-awesome-icon class="search-icon" icon="map-marker-alt" />
            {{ locationCertificationsHero.address }}
          </a>
          <span v-else>
            <font-awesome-icon class="search-icon" icon="map-marker-alt" />
            {{ locationCertificationsHero.address }}
          </span>
        </div>

        <p>{{ locationCertificationsHero.description }}</p>

        <div class="document-badges">
          <div
            v-for="documentBadge in locationCertificationsHero.documentBadges"
            :key="documentBadge.id"
            class="document-badge"
          >
            <a
              v-if="documentBadge.document"
              :href="documentBadge.document[0].url"
              target="_blank"
            >
              <img
                :src="documentBadge.badgeAttachment[0].image[0].url"
                :alt="documentBadge.badgeAttachment[0].image[0].title"
              />
            </a>
            <img
              v-else
              :src="documentBadge.image[0].url"
              :alt="documentBadge.title"
            />
          </div>
        </div>

        <div class="documents-list">
          <ul class="first-list">
            <li v-for="document in firstDocumentsList" :key="document.id">
              <a :href="document.document[0].url" target="_blank">{{
                document.title
              }}</a>
            </li>
          </ul>

          <ul class="second-list">
            <li v-for="document in secondDocumentsList" :key="document.id">
              <a :href="document.document[0].url" target="_blank">{{
                document.title
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationCertificationsHeroContainer',
  props: {
    locationCertificationsHero: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundImageUrl() {
      return this.locationCertificationsHero
        ? this.locationCertificationsHero.backgroundImage[0].url
        : ''
    },
    documentsList() {
      return this.locationCertificationsHero.documentsList
    },
    firstDocumentsList() {
      const documentsList = this.documentsList

      return documentsList.slice(0, Math.ceil(documentsList.length / 2))
    },
    secondDocumentsList() {
      const documentsList = this.documentsList
      let start = Math.floor(documentsList.length / 2),
        end = documentsList.length

      return documentsList.slice(start, end)
    }
  },
  watch: {
    backgroundImageUrl(newVal) {
      if (newVal.length) {
        this.preloadImage()
      }
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      if (!this.backgroundImageUrl.length) return

      const preloader = document.createElement('img')

      preloader.addEventListener('load', () => {
        this.$emit('loaded')
      })

      preloader.src = this.backgroundImageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.location-certifications-hero-container {
  @apply bg-cover;
  @apply bg-no-repeat;
  @apply bg-center;
  min-height: 120vh;

  .container {
    .certifications-body {
      padding-top: 80px;

      h1 {
        @apply uppercase text-white;
        font-size: 32px;
        letter-spacing: 1px;
        text-shadow: 0 0 15px rgba(60, 60, 60, 0.3);
      }

      h2 {
        @apply text-white uppercase mt-6;
        font-size: 18px;
        letter-spacing: 2px;
      }

      .address {
        @apply text-white;
        font-size: 16px;
      }

      p {
        @apply w-5/6 mx-auto mt-6 text-white;
        font-size: 22px;
        letter-spacing: 1px;
      }

      .document-badges {
        @apply flex flex-wrap justify-evenly items-center w-full mx-auto;
        margin-top: 40px;

        .document-badge {
          @apply w-1/3 px-6 pt-4;

          &:hover {
            img {
              transform: scale(1.1);
            }
          }

          img {
            transition: all 0.3s ease;
            margin-bottom: 24px;
            filter: drop-shadow(0 0 10px rgba(60, 60, 60, 0.7));
          }
        }
      }

      .documents-list {
        @apply flex flex-wrap justify-evenly mt-16 mb-48 mx-auto;

        ul {
          @apply w-full block;

          li {
            @apply my-4 text-center list-disc text-white;
            font-size: 22px;
            letter-spacing: 2px;
            a {
              @apply text-white;

              &:hover {
                color: rgba(255, 255, 255, 0.7);
              }
            }
          }
        }
      }
    }
  }

  ///////////////////////////////////////////

  @media screen and (min-width: 600px) {
    .container {
      .certifications-body {
        padding-top: 120px;

        h1 {
          font-size: 48px;
        }

        .address {
          font-size: 20px;
        }

        p {
          @apply w-9/12;
        }

        .document-badges {
          @apply w-11/12;
          margin-top: 80px;

          .document-badge {
            @apply w-1/4 px-6 pt-0;
          }
        }

        .documents-list {
          ul {
            li {
              font-size: 34px;
            }
          }
        }
      }
    }
  }

  ///////////////////////////////////////////

  @media screen and (min-width: 900px) {
    .container {
      .certifications-body {
        padding-top: 160px;

        h1 {
          font-size: 68px;
        }

        h2 {
          font-size: 20px;
        }

        p {
          font-size: 26px;
        }

        .document-badges {
          @apply flex-no-wrap w-4/5;

          .document-badge {
            @apply w-2/12 px-6;
          }
        }

        .documents-list {
          @apply flex-no-wrap mt-16 mb-48;

          ul {
            @apply w-auto;
            li {
              @apply my-0 text-left;
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  ///////////////////////////////////////////

  @media screen and (min-width: 1200px) {
    .container {
      .certifications-body {
        h2 {
          font-size: 22px;
        }

        p {
          @apply w-7/12;
          font-size: 32px;
        }

        .document-badges {
          .document-badge {
            @apply px-8;
          }
        }

        .documents-list {
          @apply w-4/5 mt-16 mb-48;

          ul {
            li {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  ///////////////////////////////////////////

  @media screen and (min-width: 1500px) {
    .container {
      .certifications-body {
        .documents-list {
          @apply mt-24 mb-48;

          ul {
            li {
              font-size: 34px;
            }
          }
        }
      }
    }
  }
}
</style>
